"use client";

import { useState } from "react";
import { MdOutlineShare } from "react-icons/md";
import {
  FaWhatsapp,
  FaEnvelope,
  FaXTwitter,
  FaFacebook,
} from "react-icons/fa6";

const SocialShareButton = ({ name, url }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const shareText = encodeURIComponent(`Schau dir diesen Cocktail an: ${name}`);
  const shareUrl = encodeURIComponent(url);

  const whatsappUrl = `https://api.whatsapp.com/send?text=${shareText}%20${shareUrl}`;
  const emailUrl = `mailto:?subject=Cocktail%20des%20Tages&body=${shareText}%20${shareUrl}`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${shareText}&url=${shareUrl}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="text-md md:text-3xl text-amber-700 hover:text-amber-900 dark:text-slate-200 dark:hover:text-slate-100"
        style={{ backgroundColor: "transparent" }}
      >
        <MdOutlineShare />
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-20 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg z-30">
          <ul className="grid grid-cols-2 gap-2 p-2">
            <li>
              <a
                href={whatsappUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center text-green-500 hover:text-green-700"
              >
                <FaWhatsapp size={24} />
              </a>
            </li>
            <li>
              <a
                href={emailUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center text-blue-500 hover:text-blue-700"
              >
                <FaEnvelope size={24} />
              </a>
            </li>
            <li>
              <a
                href={twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center text-blue-400 hover:text-blue-600"
              >
                <FaXTwitter size={24} />
              </a>
            </li>
            <li>
              <a
                href={facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center text-blue-700 hover:text-blue-900"
              >
                <FaFacebook size={24} />
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SocialShareButton;
