"use client";
import { useState } from "react";
import Image from "next/image";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import sanitizeAndParse from "../_lib/sanitizeAndParse";
import SocialShareButton from "./SocialShareButton";

const getYouTubeVideoId = (url) => {
  if (!url) return null;
  const regExp =
    /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=|watch\?vi=|watch\?.+&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

const formatDate = (date) => {
  try {
    return format(new Date(date), "dd.MM.yyyy", { locale: de });
  } catch (error) {
    console.error("Invalid date value:", error);
    return "Invalid date";
  }
};

const CocktailCard = ({
  name = "",
  date = "",
  ingredients = [],
  glasses = [],
  equipment = [],
  description = "",
  history = "",
  tips = "",
  videoUrl = "",
  preparationSteps = [],
  personalities = [],
  garnish = [],
  imageUrl = "",
}) => {
  const [servings, setServings] = useState(1);

  const incrementServings = () => {
    setServings((prev) => prev + 1);
  };

  const decrementServings = () => {
    if (servings > 1) {
      setServings((prev) => prev - 1);
    }
  };

  const calculateGlassAmount = (glass, servings) => {
    return glass.amount * servings;
  };

  const shareUrl = typeof window !== "undefined" ? window.location.href : "";

  const videoId = getYouTubeVideoId(videoUrl);

  return (
    <div className="bg-amber-50 dark:bg-slate-700 text-amber-600 dark:text-slate-200 p-6 rounded-lg shadow-lg mt-6">
      <div className="relative h-[300px] w-full md:h-screen md:w-[600px] grid grid-cols-1">
        {imageUrl && (
          <div className="relative w-full h-full md:h-[100vh] md:w-[600px] shadow-[8px_8px_0px_0px_rgba(0,0,0,0.1)] rounded-br-lg">
            <Image
              src={imageUrl}
              alt={`Cocktail ${name}`}
              fill
              sizes="(max-width: 600px) 100vw, 600px"
              priority
              className="object-cover rounded-br-lg"
            />
            <div className="absolute inset-0 flex items-center justify-center md:justify-end w-full h-full">
              <h1 className="text-4xl md:text-6xl font-bold text-center bg-amber-200/[0.25] dark:bg-slate-300/[0.47] dark:text-slate-200 text-amber-950 shadow-md px-4 py-2 md:transform md:translate-x-2/3">
                {name}
              </h1>
            </div>
          </div>
        )}
      </div>
      <p className="italic mb-4 md:col-span-2 dark:text-slate-50 pt-4">
        Garnierung:{" "}
        {garnish.map((g, index) => (
          <span key={index}>
            {g.affiliateLink ? (
              <a
                href={g.affiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {g.name}
              </a>
            ) : (
              g.name
            )}
            {index < garnish.length - 1 ? ", " : ""}
          </span>
        ))}
      </p>
      <div className="mb-4 md:col-span-2 grid grid-cols-2 items-center">
        <h3 className="text-l md:text-2xl font-semibold mb-2">
          Zutaten für {servings} {servings > 1 ? "Drinks" : "Drink"}
        </h3>
        <div className="flex justify-end pr-2 md:pr-6 lg:pr-10">
          <SocialShareButton name={name} url={shareUrl} />
        </div>
      </div>
      <div className="flex items-center mb-4">
        <button
          onClick={decrementServings}
          className="px-2 py-1 bg-amber-900 dark:bg-slate-600 text-amber-100 dark:text-slate-100 rounded"
        >
          -
        </button>
        <input
          type="text"
          value={servings}
          readOnly
          className="mx-2 w-12 text-center text-amber-900 dark:text-slate-500"
        />
        <button
          onClick={incrementServings}
          className="px-2 py-1 bg-amber-900 dark:bg-slate-600 text-amber-100 dark:text-slate-100 rounded"
        >
          +
        </button>
      </div>
      <ul className="list-none pl-5 text-lg">
        {ingredients.map((ingredient, index) => (
          <li key={index}>
            {(ingredient.amount * servings).toFixed(1)} {ingredient.unit}{" "}
            {ingredient.affiliateLink ? (
              <a
                href={ingredient.affiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {ingredient.name}
              </a>
            ) : (
              ingredient.name
            )}
          </li>
        ))}
      </ul>

      <div className="mb-4 md:col-span-1">
        <h3 className="text-l md:text-2xl font-semibold mb-2">
          {servings > 1 ? "Welche Gläser passen" : "Welches Glas passt"} am
          besten zum {name}
        </h3>
        <ul className="list-none pl-5 text-lg">
          {glasses.map((glass, index) => (
            <li key={index}>
              {glass.affiliateLink ? (
                <a
                  href={glass.affiliateLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {calculateGlassAmount(glass, servings)} {glass.type}
                </a>
              ) : (
                `${calculateGlassAmount(glass, servings)} ${glass.type}`
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="mb-4 md:col-span-1">
        <h3 className="text-xl md:text-2xl font-semibold mb-2">Equipment</h3>
        <ul className="list-none pl-5 text-lg">
          {equipment.map((item, index) => (
            <li key={index}>
              {item.affiliateLink ? (
                <a
                  href={item.affiliateLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {`${item.amount} ${item.name}`}
                </a>
              ) : (
                `${item.amount} ${item.name}`
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="mb-4 md:col-span-2 shadow-md p-4 rounded">
        <h3 className="text-l md:text-2xl font-semibold mb-2">Beschreibung</h3>
        <div className="text-base md:text-lg leading-relaxed text-justify">
          {sanitizeAndParse(description)}
        </div>
      </div>
      <div className="mb-4 md:col-span-2 shadow-md p-4 rounded">
        <h3 className="text-l md:text-2xl font-semibold mb-2">Geschichte</h3>
        <div className="text-base md:text-lg leading-relaxed text-justify">
          {sanitizeAndParse(history)}
        </div>
      </div>
      <div className="mb-4 md:col-span-2 shadow-md p-4 rounded">
        <h3 className="text-l md:text-2xl font-semibold mb-2">Tipps</h3>
        <div className="text-base md:text-lg leading-relaxed text-justify">
          {sanitizeAndParse(tips)}
        </div>
      </div>
      <div className="mb-4 md:col-span-2 shadow-md p-4 rounded">
        <h3 className="text-xl md:text-2xl font-semibold mb-2">Zubereitung</h3>
        <ol className="list-none pl-5 text-lg">
          {preparationSteps.map((step, index) => (
            <li key={index}>{`${index + 1}. ${step}`}</li>
          ))}
        </ol>
      </div>
      <div className="mb-4 md:col-span-2 shadow-md p-4 rounded">
        <h3 className="text-l md:text-2xl font-semibold mb-2">
          Auf wen wir heute anstoßen können:
        </h3>
        <ul className="list-none pl-5 text-lg">
          {personalities.map((person, index) => (
            <li key={index}>
              {person.infoLink ? (
                <a
                  href={person.infoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {person.name}
                </a>
              ) : (
                person.name
              )}{" "}
              *{formatDate(person.birthday)}
            </li>
          ))}
        </ul>
      </div>
      {videoId && (
        <div className="mb-4 md:col-span-2">
          <h3 className="text-l md:text-xl font-semibold mb-2">Video</h3>
          <div className="relative w-full" style={{ paddingBottom: "56.25%" }}>
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default CocktailCard;
