import DOMPurify from "dompurify";
import parse, { domToReact } from "html-react-parser";

const sanitizeAndParse = (content) => {
  let DOMPurifyInstance;

  if (typeof window !== "undefined") {
    DOMPurifyInstance = DOMPurify(window);
  } else {
    const { JSDOM } = require("jsdom");
    const window = new JSDOM("").window;
    DOMPurifyInstance = DOMPurify(window);
  }

  const cleanContent = DOMPurifyInstance.sanitize(content);

  const options = {
    replace: (domNode) => {
      if (domNode.name === "a") {
        const { attribs, children } = domNode;
        return (
          <a href={attribs.href} target="_blank" rel="noopener noreferrer">
            {domToReact(children, options)}
          </a>
        );
      }
    },
  };

  return parse(cleanContent, options);
};

export default sanitizeAndParse;
