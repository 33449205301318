"use client";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Image from "next/image";
import Link from "next/link";
import { MdOutlineReadMore } from "react-icons/md";
import sanitizeAndParse from "../_lib/sanitizeAndParse";

const extractFirstWords = (description, wordLimit = 15) => {
  const words = description.split(" ");
  return words.slice(0, wordLimit).join(" ") + "...";
};

const CocktailSlider = ({ similarCocktails }) => {
  return (
    <div className="relative p-8 mt-8">
      <h2 className="text-2xl font-bold text-amber-900 dark:text-slate-100 mb-6 text-center">
        Lust auf mehr Cocktails?
      </h2>

      <Splide
        options={{
          perPage: 3,
          perMove: 1,
          rewind: true,
          gap: "1rem",
          pagination: false,
          breakpoints: {
            768: {
              perPage: 1,
            },
          },
        }}
      >
        {similarCocktails.map((cocktail) => (
          <SplideSlide key={cocktail._id}>
            <div className="w-[300px] h-[400px] max-w-full px-2 bg-amber-50 dark:bg-slate-700 p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
              <div className="relative w-full h-48">
                <Image
                  src={cocktail.imageUrl}
                  alt={cocktail.name}
                  fill
                  sizes="(max-width: 768px) 100vw, 33vw"
                  className="object-cover rounded-md"
                  priority
                />
              </div>

              <h3 className="mt-4 text-lg font-bold text-amber-900 dark:text-slate-100 text-center">
                {cocktail.name}
              </h3>

              <div className="mt-2 text-sm text-amber-900 dark:text-slate-100">
                {sanitizeAndParse(extractFirstWords(cocktail.description))}
              </div>

              <div className="flex justify-center mt-4">
                <Link
                  href={`/cocktails/${encodeURIComponent(cocktail.name)}`}
                  className="text-amber-600 dark:text-slate-300 hover:underline"
                  aria-label={`Mehr erfahren über ${cocktail.name}`}
                >
                  <MdOutlineReadMore size={24} />
                </Link>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default CocktailSlider;
